<template>
  <v-container>
    <h3>{{$t('message.create-shop-title')}}</h3>
    <v-card flat>
      <v-col class="pr-2 pb-2" cols="12">
        <form @submit.prevent="submit">
          <v-row>
            <v-col cols="12" md="2" xs="2" class="label-class">
              <span class="label">{{$t('message.store-name')}}</span>
            </v-col>
            <v-col cols="12" md="10" xs="2" class="input-class">
              <v-text-field
                outlined
                required
                v-model="shopAdd.store_name"
                :error-messages="storeErrors"
                @input="$v.shopAdd.store_name.$touch()"
                @blur="$v.shopAdd.store_name.$touch()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2" xs="2" class="label-class">
              <span class="label">{{$t('message.postal-code')}}</span>
            </v-col>
            <v-col cols="12" md="10" xs="2" class="input-class">
              <v-row align="center">
                <v-col cols="8">
                  <v-text-field
                    outlined
                    required
                    v-model="shopAdd.postal_code"
                    :error-messages="postal_codeErrors"
                    @input="$v.shopAdd.postal_code.$touch()"
                    @blur="$v.shopAdd.postal_code.$touch()"
                  ></v-text-field>
                </v-col>
                <v-col class="mb-8">
                  <v-btn
                    depressed
                    outlined
                    @click.prevent="getAddress()"
                    :disabled="isPostal"
                    >
                    <span>
                      {{ $t("message.search") }}
                    </span>
                    </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="2" xs="2" class="label-class">
              <span class="label">{{$t('message.prefecture')}}</span>
            </v-col>
            <v-col cols="12" md="10" xs="2" class="input-class">
              <v-text-field
                outlined
                required
                v-model="shopAdd.prefecture"
                :error-messages="prefectureErrors"
                @input="$v.shopAdd.prefecture.$touch()"
                @blur="$v.shopAdd.prefecture.$touch()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2" xs="2" class="label-class">
              <span class="label">{{$t('message.city')}}</span>
            </v-col>
            <v-col cols="12" md="10" xs="2" class="input-class">
              <v-text-field
                outlined
                required
                v-model="shopAdd.city"
                :error-messages="cityErrors"
                @input="$v.shopAdd.city.$touch()"
                @blur="$v.shopAdd.city.$touch()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2" xs="2" class="label-class">
              <span class="label">{{$t('message.district')}}</span>
            </v-col>
            <v-col cols="12" md="10" xs="2" class="input-class">
              <v-text-field
                outlined
                required
                v-model="shopAdd.district"
                :error-messages="districtErrors"
                @input="$v.shopAdd.district.$touch()"
                @blur="$v.shopAdd.district.$touch()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2" xs="2" class="label-class">
              <span class="label">{{$t('message.address')}}</span>
            </v-col>
            <v-col cols="12" md="10" xs="2">
              <v-text-field
                outlined
                class="input-class"
                required
                v-model="shopAdd.store_address"
                :error-messages="addressErrors"
                @input="$v.shopAdd.store_address.$touch()"
                @blur="$v.shopAdd.store_address.$touch()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2" xs="2" class="label-class">
              <span class="">{{$t('message.phone')}}</span>
            </v-col>
            <v-col cols="12" md="10" xs="2">
              <v-text-field 
                outlined
                required
                v-model="shopAdd.phone"
                :error-messages="phoneErrors"
                @input="$v.shopAdd.phone.$touch()"
                @blur="$v.shopAdd.phone.$touch()"
              />
            </v-col>
            <v-col cols="12" md="2" xs="2" class="label-class">
              <span class="label">{{$t('message.open-day')}}</span>
            </v-col>
            <v-col cols="12" md="10" xs="2" class="pt-0">
              <v-row no-gutters align="center" class="input-class">
                <v-col
                  v-for="(item, i) in active_days"
                  :key="i"
                  cols="3"
                  class="pt-0 pe-0"
                  xs="1"
                >
                  <v-checkbox
                    :label="item.name_day"
                    color="indigo"
                    :value="item"
                    v-model="shopAdd.selected_active"
                    @change="checkDay(i)"
                    hide-details
                    required
                    :error-messages="selected_activeErrors"
                    @input="$v.shopAdd.selected_active.$touch()"
                    @blur="$v.shopAdd.selected_active.$touch()"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="2" xs="2" class="label-class">
              <span class="label">{{$t('message.open-hour')}}</span>
            </v-col>
            <v-col cols="12" md="10" xs="2" class="input-class">
              <v-row no-gutters class="row-amount">
                <v-col cols="11" sm="5">
                  <v-dialog
                    ref="dialog"
                    v-model="modal1"
                    :return-value.sync="shopAdd.open_hour"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                      
                        v-model="shopAdd.open_hour"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        required
                        :error-messages="open_hourErrors"
                        @input="$v.shopAdd.open_hour.$touch()"
                        @blur="$v.shopAdd.open_hour.$touch()"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="modal1"
                      v-model="shopAdd.open_hour"
                      full-width
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modal1 = false">
                        {{$t('message.cancel')}}
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog.save(shopAdd.open_hour)"
                      >
                        {{$t('message.ok')}}
                      </v-btn>
                    </v-time-picker>
                  </v-dialog>
                </v-col>
                <v-col class="minus-amount">
                  <v-icon
                    class="icon-tilde-class"
                    style="margin-right: 20px; margin-left: 20px"
                    >mdi-tilde</v-icon
                  >
                </v-col>
                <v-col cols="11" sm="5">
                  <v-dialog
                    ref="dialog2"
                    v-model="modal2"
                    :return-value.sync="shopAdd.close_hour"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="shopAdd.close_hour"
                        prepend-icon="mdi-clock-time-four-outline"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        required
                        :error-messages="close_hourErrors"
                        @input="$v.shopAdd.close_hour.$touch()"
                        @blur="$v.shopAdd.close_hour.$touch()"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="modal2"
                      v-model="shopAdd.close_hour"
                      full-width
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modal2 = false">
                        {{$t('message.cancel')}}
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog2.save(shopAdd.close_hour)"
                      >
                        {{$t('message.ok')}}
                      </v-btn>
                    </v-time-picker>
                  </v-dialog>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" md="2" xs="2" class="label-class">
              <span class="label">{{$t('message.stripe-key')}}</span>
            </v-col>
            <v-col cols="12" md="10" xs="2" class="input-class">
              <v-text-field
                outlined
                v-model="shopAdd.stripeKey"
                :error-messages="stripeErrors"
                @input="$v.shopAdd.stripeKey.$touch()"
                @blur="$v.shopAdd.stripeKey.$touch()"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2" xs="2" class="label-class">
              <span class="label">{{$t('message.image')}}</span>
            </v-col>
            <v-col cols="12" md="10" xs="2" class="input-class">
              <v-file-input
                outlined
                class="file-input-class"
                accept="image/*"
                v-model="shopAdd.images"
                @change="upload"
                type="file"
                ref="myfile"
                id="file-input"
                required
                
              >
              </v-file-input>
              <v-row
                  v-if="isLoadingImg"
                  class="fill-height ma-0"
              >
                  <v-progress-circular
                  indeterminate
                  color="blue"
                  ></v-progress-circular>
              </v-row>
              <v-img
                :lazy-src="this.shopAdd.image_url"
                :src="this.shopAdd.image_url"
                max-height="150"
                max-width="180px"
              >
              </v-img>
            </v-col>

            <v-col cols="12" md="2" xs="2" class="label-class">
              <span class="label">{{ $t("message.store-image") }}</span>
            </v-col>
            <v-col cols="12" md="10" xs="2" class="input-class">
              <v-file-input
                outlined
                class="file-input-class"
                accept="image/*"
                v-model="shopAdd.store_header_images"
                @change="uploadImgByStore"
                type="file"
                ref="myfile"
                id="file-input"
              >
              </v-file-input>
              <v-row
                  v-if="isLoadingImgStore"
                  class="fill-height ma-0"
              >
                  <v-progress-circular
                  indeterminate
                  color="blue"
                  ></v-progress-circular>
              </v-row>
            </v-col>
            <template>
            <v-container class="d-flex justify-end" >
              <v-row class="mb-6" no-gutters justify="start">
                <v-col cols="2"></v-col>
                <v-col v-for="(img, i) in uploadImg" :key="i">
                  <v-icon @click.prevent="deleteImg(img.id, i)">
                    {{ closePage }}
                  </v-icon>
                  <v-img
                    :lazy-src="img.image_url"
                    :src="img.image_url"
                    max-height="150px"
                    max-width="180px"
                    class=""
                    width="150"
                  >
                  </v-img>
                </v-col>
              </v-row>
            </v-container>
          </template>
          </v-row>
          <div class="d-flex flex-row-reverse mt-3 me-2" tile>
            <div class="m-2">
              <v-btn type="submit" color="primary" @click.prevent="submit">
                <div v-if="isLoading" style="margin-right:10px">
                  <v-progress-circular
                    indeterminate
                    color="white"
                    size="11"
                    left
                  ></v-progress-circular>
                </div>
                {{$t('message.submit-button')}}
              </v-btn>
            </div>
            <div class="d-flex flex-row-reverse me-0" tile>
              <div class="m-2">
                <v-btn color="grey" @click="$router.go(-1)">
                  {{$t('message.back-button')}}
                </v-btn>
              </div>
            </div>
          </div>
        </form>
      </v-col>
    </v-card>
  </v-container>
</template>

<script>
import { mdiCloseThick, mdiMagnify } from "@mdi/js";
import { validationMixin } from "vuelidate";
import { required, maxLength, helpers, numeric } from "vuelidate/lib/validators";
import Swal from "sweetalert2/dist/sweetalert2.js";
const whiteSpace = helpers.regex('whitespacing', /^\S*$/);

export default {
  name: "CreateShop",
  mixins: [validationMixin],
  validations: {
    shopAdd: {
      store_name: { required },
      store_address: { required },
      phone: { numeric: numeric, maxLength: maxLength(12) },
      prefecture: { required },
      city: { required },
      district: { required },
      postal_code: { required, maxLength: maxLength(8) },
      images: { required },
      open_hour: { required },
      close_hour: { required },
      selected_active: { required },
      stripeKey: {whiteSpace}
    },
  },
  data: () => ({
    closePage: mdiCloseThick,
    modal1: false,
    modal2: false,
    isLoading: false,
    isPostal: true,
    shopAdd: {
      store_name: "",
      store_address: "",
      prefecture: "",
      city: "",
      district: "",
      postal_code: "",
      phone: "",
      images: null,
      image_url: null,
      open_hour: null,
      close_hour: null,
      stripeKey: "",
      selected_active: [],
      store_header_images: null,
      store_img: null
    },
    active_days: [
      {
        day_id: 1,
        is_open: 0,
        name_day: "月",
      },
      {
        day_id: 2,
        is_open: 0,
        name_day: "火",
      },
      {
        day_id: 3,
        is_open: 0,
        name_day: "水",
      },
      {
        day_id: 4,
        is_open: 0,
        name_day: "木",
      },
      {
        day_id: 5,
        is_open: 0,
        name_day: "金",
      },
      {
        day_id: 6,
        is_open: 0,
        name_day: "土",
      },
      {
        day_id: 7,
        is_open: 0,
        name_day: "日",
      },
    
    ],
    isLoadingImg: false,
    isLoadingImgStore: false,
    message: {},
    iconSearch: mdiMagnify
  }),
  methods: {
    async uploadImgByStore() {
      this.isLoadingImgStore = true
      if(this.shopAdd.store_header_images != null) {
        await this.$store.dispatch("uploadImage_module/uploadImg", {
          images: this.shopAdd.store_header_images,
          path: "store_image"
        })
      }
      this.isLoadingImgStore = false
    },
    deleteImg(imageId, i) {
      return this.$store.dispatch("uploadImage_module/deleteStateItem", {
        index: i,
      });
    },
    async upload(event) {
      this.isLoadingImg = true
      if(this.shopAdd.images != null) {
        await this.$store.dispatch("uploadImage_module/upload", {
          images: this.shopAdd.images,
          path: "itemsV2",
        })
        let uploadImgLink = await this.$store.state.uploadImage_module.resLinkImg;
        this.shopAdd.image_url = uploadImgLink.image_url
      } else {
        this.shopAdd.image_url = null
      }
      this.isLoadingImg = false
    },
    checkDay(index) {
      let current_check = this.active_days[index].is_open;
      if (current_check) {
        this.active_days[index].is_open = 0;
      } else {
        this.active_days[index].is_open = 1;
      }
    },
    formatDay(day) {
      if (day === 1) return "Mon";
      if (day === 2) return "Tue";
      if (day === 3) return "Wed";
      if (day === 4) return "Thrus";
      if (day === 5) return "Fri";
      if (day === 6) return "Sat";
      if (day === 7) return "Sun";
    },
    async submit() {
      this.isLoading = true;
      this.message.title = this.$t("message.title-createShop-success-message")
      this.$v.$touch()

      let refacPostcode = this.shopAdd.postal_code
      refacPostcode = refacPostcode.replace("-","")

      let payload = {
        store_name: this.shopAdd.store_name,
        store_address: this.shopAdd.store_address,
        prefecture: this.shopAdd.prefecture,
        city: this.shopAdd.city,
        district: this.shopAdd.district,
        phone: this.shopAdd.phone,
        postal_code: refacPostcode,
        image_url: this.shopAdd.image_url,
        open_hour: this.shopAdd.open_hour,
        close_hour: this.shopAdd.close_hour,
        active_days: this.active_days,
        stripe_account_key: this.shopAdd.stripeKey,
        store_header_images: this.$store.state.uploadImage_module.resLink
      };      
      let res = await this.$store.dispatch("shop_module/addShopList", {
        payload,
        message: {
          title: this.message.title
        }
      });
      if (res.status == 200) {
        this.$store.dispatch("uploadImage_module/clearImg", []);
        this.isLoading = false;
        return this.$router.push("/shop");
      }
      this.isLoading = false;
    },
    async getAddress(){
      let postalcode = this.shopAdd.postal_code
      postalcode = postalcode.replace("-","")
      let resp = await this.$store.dispatch("shop_module/getAddressByPostal", {postal: postalcode})
      if(resp.data.length > 0){
        let respaddr = resp.data[0]
        this.shopAdd.prefecture = respaddr.pref
        this.shopAdd.city = respaddr.city
        this.shopAdd.district = respaddr.town
      }else{
        let message
        if(typeof resp.data.message === 'undefined') message = this.$t("message.postal-code-notfound-message")
        else message = this.$t("message.postal-code-api-error-message")

        this.shopAdd.prefecture = ""
        this.shopAdd.city = ""
        this.shopAdd.district = ""
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 1000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            },
          })
          Toast.fire({
            icon: 'warning',
            title: message
          })
      }
    },
  },
  mounted() {
    this.$store.dispatch("uploadImage_module/clearImg");
  },
  computed: {
    uploadImg() {
      return this.$store.state.uploadImage_module.resLink;
    },
    stripeErrors() {
      const errors = [];
      !this.$v.shopAdd.stripeKey.whiteSpace &&
        errors.push(this.$t('message.err-stripe'));
      return errors;
    },
    storeErrors() {
      const errors = [];
      if (!this.$v.shopAdd.store_name.$dirty) return errors;
      !this.$v.shopAdd.store_name.required &&
        errors.push(this.$t('message.err-store-name'));
      return errors;
    },
    phoneErrors() {
      const errors = [];
      if(!this.$v.shopAdd.phone.$dirty) return errors;
      !this.$v.shopAdd.phone.numeric &&
        errors.push(this.$t("message.err-msg-phone-numeric"));
      !this.$v.shopAdd.phone.maxLength &&
        errors.push(this.$t("message.err-msg-phone-maxLength"));
      return errors;
    },
    addressErrors() {
      const errors = [];
      if (!this.$v.shopAdd.store_address.$dirty) return errors;
      !this.$v.shopAdd.store_address.required &&
        errors.push(); //err-address
      return errors;
    },
    prefectureErrors() {
      const errors = [];
      if (!this.$v.shopAdd.prefecture.$dirty) return errors;
      !this.$v.shopAdd.prefecture.required &&
        errors.push(this.$t('message.err-prefecture'));
      return errors;
    },
    cityErrors() {
      const errors = [];
      if (!this.$v.shopAdd.city.$dirty) return errors;
      !this.$v.shopAdd.city.required && errors.push(this.$t('message.err-city'));
      return errors;
    },
    districtErrors() {
      const errors = [];
      if (!this.$v.shopAdd.district.$dirty) return errors;
      !this.$v.shopAdd.district.required &&
        errors.push(this.$t('message.err-district'));
      return errors;
    },
    postal_codeErrors() {
      const errors = [];
      this.isPostal = false
      if (!this.$v.shopAdd.postal_code.$dirty) return errors;
      !this.$v.shopAdd.postal_code.required &&
        errors.push(this.$t('message.err-postal-req'));
      !this.$v.shopAdd.postal_code.maxLength &&
        errors.push(this.$t('message.err-postal-digit'))
      if(errors.length > 0) {
        this.isPostal = true
      }
      return errors;
    },
    // imagesErrors() {
    //   const errors = [];
    //   if (!this.$v.shopAdd.images.$dirty) return errors;
    //   !this.$v.shopAdd.images.required &&
    //     errors.push(this.$t('message.err-images'));
    //   return errors;
    // },
    open_hourErrors() {
      const errors = [];
      if (!this.$v.shopAdd.open_hour.$dirty) return errors;
      !this.$v.shopAdd.open_hour.required &&
        errors.push(this.$t('message.err-open-hour'));
      return errors;
    },
    close_hourErrors() {
      const errors = [];
      if (!this.$v.shopAdd.close_hour.$dirty) return errors;
      !this.$v.shopAdd.close_hour.required &&
        errors.push(this.$t('message.err-close-hour'));
      return errors;
    },
    selected_activeErrors() {
      const errors = [];
      if (!this.$v.shopAdd.selected_active.$dirty) return errors;
      !this.$v.shopAdd.selected_active.required &&
        errors.push(this.$t('message.err-selected-active'));
      return errors;
    },
  },
  created() {},
};
</script>

<style>
.v-input .v-input__control .v-text-field__details {
  display: inline-block !important;
}
.file-input-class .v-input__prepend-outer {
  margin-top: 0px !important;
}
.v-text-field .v-input__control .v-input__slot {
  min-height: 20px !important;
  display: flex !important;
  align-items: center !important;
}
.form-inline {
  justify-content: flex-end;
}
.row-amount {
  align-items: center;
}
.minus-amount {
  max-width: fit-content;
}
.minus-amount .icon-tilde-class {
  font-size: 15px;
  margin-left: 3px;
  margin-right: 3px;
  margin-top: 8px;
}
.label-class {
  margin-top: 10px;
  padding-bottom: 0px;
  padding-right: 0px;
  padding-top: 0px;
}
.row .input-class {
  padding-top: 0px;
}
.v-input--hide-details {
  margin-top: 0px;
}
</style>
